import {SelectOptionInterface} from "../../interfaces/SelectOptionInterface";
import {config} from "../../config";

const mapSelectOption = (value: string|number|any, options: Array<SelectOptionInterface>) => {
    let option: SelectOptionInterface|undefined|any = options.find((o: SelectOptionInterface) => o.value == value);

    return option;
}

const convertParamUrlToObject = (strParams: string = '') => {
    try {
        let  search = strParams?.substring(1);
        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    }catch (err){}

    return  {}
}

const getItemLocal = (key: string, isParse = false) => {
    try {
        let data: any = localStorage.getItem(key)

        if(isParse){
            data = JSON.parse(data)
        }

        return data
    }catch (err) {}
    return null
}

export const __object = {
    mapSelectOption,
    convertParamUrlToObject,
    getItemLocal,
}
