export interface PaginationInterface {
    page: number,
    total: number,
    per_page: number,
    search: string,
    sort: 'asc'|'desc'|string,
    key: string,
}

export const structPagination: PaginationInterface = {
    page: 1,
    total: 0,
    per_page: 20,
    search: '',
    sort: 'asc',
    key: 'id',
}