import axios from "axios";
import {config} from "../../config";
import {QHistory} from "../../QHistory";
import {RouteDefine} from "../../routes/RouteDefine";

// default
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error?.response?.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                localStorage.clear();
                return QHistory.push(RouteDefine.login)
                message = "Invalid credentials";
                break;
            case 404:
                message = "Sorry! the data you are looking for could not be found";
                break;
            default:
                message = error.message || error;
        }
        if(error?.response?.status == 400)
            return Promise.reject(error?.response);
        else
            return Promise.reject(message);
    }
);

const setAuthorization = (token: string) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const APIClient = {
     get: (url: string, params: object|any, isAuth = true) => {
        let response;

        let paramKeys: Array<any> = [];

        if (params) {
            Object.keys(params).map(key => {
                paramKeys.push(key + '=' + params[key]);
                return paramKeys;
            });

            const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }

        return response;
    },

    create: (url: string, data : any, isAuth = true) => {
        return axios.post(url, data);
    },
    /**
     * Updates data
     */
    update: (url: string, data: any, isAuth = true) => {
        return axios.put(url, data);
    },

    delete: (url: string, config : any, isAut = true) => {
        return axios.delete(url, { ...config });
    },
}
const getLoggedinUser = () => {
    const user = localStorage.getItem(config.LOCAL_STORE.ACCOUNT);
    if (!user) {
        return null;
    } else {
        return JSON.parse(user);
    }
};


export { APIClient, setAuthorization, getLoggedinUser };
