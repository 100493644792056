import React from "react";
import IconDone from "../../../../assets/images/ams/done.png";
import {useHistory} from "react-router-dom";
import QButton from "../../../../common/components/QButton";
import {RouteDefine} from "../../../../routes/RouteDefine";
interface Props {
    formik: any
}

const StepSuccess: React.FC<Props> = ({formik}) => {
    const history = useHistory();

    return (
        <React.Fragment>
            <div className="mt-5 position-relative text-center">
                <h2 className="mb-4 mt-3 fs-32 cl-done fw-400">Well done !</h2>
                <p className="cl-bdd">Aww yeah, you successfully integrated this important setup.</p>
                <div className="mt-3 mb-3">
                    <img src={IconDone} height="96" alt={'Icon done'} />
                </div>
                <div className="mt-5">
                    <QButton
                        size={''}
                        width={100}
                        content={'Back to Shipment'}
                        className={'bg-done bdr-10 fs-16'}
                        type={''}
                        onClick={() => {
                            history.push(RouteDefine.order)
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default StepSuccess