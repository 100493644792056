const status : any = {

    open: 1,
    on_hold: 2,
    shipped: 3,
    // booked: 4,
    unavailable: 5,
    all: 0,
}

const statusTitle : any = {
    all: "All",
    open: "Open",
    on_hold: "On hold",
    shipped: "Shipped",
    booked: "Booked",
    unavailable: "Unavailable",
}

export const OrderConstant = {
    status,
    statusTitle
}

export const ShipmentService = {
    deposit_service: '6'
}
