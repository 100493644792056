import {TableHeaderInterface} from "../../../../interfaces/TableHeaderInterface";

const headers : Array<TableHeaderInterface> = [
    {
        label: "",
        checkbox: true,
    },
    {
        label: "Order no",
        sort: 'orderNumber',
    },
    {
        label: "Customer",
    },
    {
        label: "Order date",
        sort: 'date'
    },
    {
        label: "Delivery date",
        sort: 'delivery_deliveryDate'
    },
    {
        label: "Delivery address",
    },
    {
        label: "Weight",
    },
    {
        label: "Status",
        className: "text-center"
    },
    {
        label: "VIA",
    },
    {
        label: "Service",
    },
    {
        label: "",
    },
]

export const OrderConfig = {
    headers,
}
