
export const ShipmentConstant = {
    service: {
        EURO_BUSINESS_SERVICE: 5,
        PRIVATE_DELIVERY_SERVICE: 4,
        DEPOSIT_SERVICE: 6,
        BUSINESS_PARCEL_SERVICE: 2,
        SHOP_DELIVERY_SERVICE: 3,
    },
    company: {
        GLS: 1,
    }
}