import React, {useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import {Button, Col, Row} from "reactstrap";
import {Formik} from "formik";
import {QHelpers} from "../../../../common/helpers";
import {BusinessInterface} from "../../../../interfaces/private/BusinessInterface";
import BusinessApi from "../../../../common/api/functions/Business";
import {setToast} from "../../../../app/rootReducer";
import {useDispatch} from "react-redux";
import QButton from "../../../../common/components/QButton";

interface Props {
    model: BusinessInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setBusiness: Function,
}

const BodyIntegrateComponent: React.FC<Props> = ({model, setOpen, setLoadingFunc,setBusiness}) => {
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Formik
                initialValues={{...model}}
                onSubmit={(values: any) => {
                    setLoadingFunc(true);
                    BusinessApi.update(values?.id, values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {
                            await setOpen(false);
                            await setLoadingFunc(true);
                            await setBusiness(response?.data);
                            await setLoadingFunc(false);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QTextBox
                                        label={'Name'}
                                        value={formik.values.name}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('name', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Cvr No.'}
                                        value={formik.values.cvr_no}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('cvr_no', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Address'}
                                        value={formik.values.address}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('address', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Zip'}
                                        value={formik.values.zip}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('zip', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'City'}
                                        value={formik.values.city}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('city', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Country'}
                                        value={formik.values.country}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('country', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Email'}
                                        value={formik.values.email}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('email', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Phone'}
                                        value={formik.values.phone}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('phone', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Website'}
                                        value={formik.values.website}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('website', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>

                                        <QButton
                                            type={'default'}
                                            onClick={() => {  setOpen(0) }}
                                            content={'Cancel'}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit() }}
                                            content={"Submit"}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyIntegrateComponent
