import {APIClient} from "../base";
import {SetupInterface} from "../../../interfaces/private/SetupInterface";

const SetupApi = {
    detail: async () => await APIClient.get('/setup', {}),
    // create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (model: SetupInterface) => await APIClient.update('/setup', model),
    // economicDetail: async () => await APIClient.get('/economic', {}),
    // economicUpdate: async (model:EconomicInterface) => await APIClient.update('/economic', model),
}

export default SetupApi;
