import React from "react";

interface Props {
    className?: string
}

const QIconSortDesc: React.FC<Props> = ({ className }) => {
    return (
        <i className={`fa fa-sort-alpha-desc ${className}`} aria-hidden="true" />
    )
}

export default QIconSortDesc