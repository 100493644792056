import React, {useRef, useState} from "react";
import Logo from "../../assets/images/logo/img.png";
import Ensign from "../../assets/images/ams/ensign.png"
import {RouteDefine} from "../../routes/RouteDefine";
import {Link, useHistory} from "react-router-dom";
import {getLoggedinUser} from "../../common/api/base";
import {config} from "../../config";
import QFullScreenDropdown from "../../common/components/Genneral/QFullScreenDropdown";
interface Props {

}

const Header: React.FC<Props> = () => {
    const history = useHistory();
    const isClickRef: any = useRef(false);
    const [isClickProfile, setIsClickProfile] = useState(false);
    let userLogged = getLoggedinUser();
    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="bg-header"/>
                <div className="container-fluid">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex">
                                {/* LOGO */}
                                <a href="#" onClick={() => { history.push(RouteDefine.order) }}><img src={Logo} alt=""/></a>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="dropdown ms-sm-3 header-item topbar-user">
                                    <button type="button" className="btn btn-icon btn-ghost-secondary rounded-circle"
                                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img id="header-lang-img" src={Ensign} alt="Header Language" className="rounded" height={'16'}/>
                                    </button>
                                   <QFullScreenDropdown />
                                    <button
                                        onClick={() => {
                                            setIsClickProfile(!isClickProfile)
                                        }}
                                        type="button"
                                        className={`btn btn-icon btn-topbar btn-ghost-secondary rounded-circle ${ isClickProfile ? 'show' : '' }`}
                                        // className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                        id="page-header-user-dropdown"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i className="las la-user fs-22 cl-icon-hd"/>
                                    </button>
                                    <div className={`dropdown-menu dropdown-menu-end ${ isClickProfile ? 'show active-profile' : '' }`}>
                                        {/* item*/}
                                        <h6 className="dropdown-header">Welcome {userLogged?.user?.name}!</h6>
                                        <a className="dropdown-item" onClick={ async (event: any) => {
                                            await localStorage.setItem(
                                                config.LOCAL_STORE.ACCOUNT,
                                                JSON.stringify(null),
                                            )
                                            history.push(`${RouteDefine.login}`);
                                        }}>
                                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"/> <span
                                            className="align-middle" data-key="t-logout">Logout</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default React.memo(Header);
