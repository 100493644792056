import {APIClient} from "../base";
import {BusinessInterface} from "../../../interfaces/private/BusinessInterface";

const BusinessApi = {
    detail: async () => await APIClient.get('/user/business-detail', {}),
    // create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (id: number, model: BusinessInterface) => await APIClient.update('/user', model),
}

export default BusinessApi;
