import React from "react";
import QTextBox from "../../../../common/components/QTextBox";

interface Props {
    formik: any
}

const Step3AccountShip: React.FC<Props> = ({formik}) => {

    return(
        <React.Fragment>
            <QTextBox
                label={'User'}
                value={formik.values.gls_username}
                onChange={(event: any) => {
                    formik.setFieldValue('gls_username', event);
                }}
            />
            <QTextBox
                label={'Key'}
                type={'password'}
                value={formik.values.gls_password}
                onChange={(event: any) => {
                    formik.setFieldValue('gls_password', event);
                }}
            />
        </React.Fragment>
    )
}

export default Step3AccountShip;
