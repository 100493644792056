import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Spinner } from 'reactstrap';
import QLoading from "../QLoading";

interface Props {
    open: boolean,
    setOpen: Function,
    headerContent: string|any,
    bodyContent: string|any,
    footerContent?: string|any,
    loading?: boolean|any,
}

const QModal: React.FC<Props> = ({
    headerContent,
    bodyContent,
    open,
    setOpen,
    loading = null,
}) => {

    return (
        <Modal
            isOpen={open}
            toggle={() => { setOpen(!setOpen) }}
            modalClassName="zoomIn"
            centered
        >
            { loading !== null ? <QLoading loading={loading} /> : null }
            <ModalHeader
                className={'modal-header border-bottom-ams p-2'}
            >
                <div>
                    { headerContent }
                </div>
                <div>
                    <button type="button" className="btn-sm btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setOpen(!setOpen)} />
                </div>
            </ModalHeader>

            <ModalBody>
                { bodyContent }
            </ModalBody>
        </Modal>
    )
}

export default QModal;