import React, {useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import OrderApi from "../../../common/api/functions/Order";
import {config} from "../../../config";
import {OrderInterface} from "../../../interfaces/private/OrderInterface";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import QPagination from "../../../common/components/QPaging";
import QLoading from "../../../common/components/QLoading";
import ProductIcon from "../../../assets/images/products/image_15.png";
import {UseExtLayout} from "../../../context/LayoutContext";
import Moment from "react-moment";
import QSearch from "../../../common/components/Genneral/QSearch";
import moment from "moment";
import styles from "./index.module.css";
import QSelectPerPage from "../../../common/components/Genneral/QSelectPerPage";
import ShipmentApi from "../../../common/api/functions/Shipment";
import {QHelpers} from "../../../common/helpers";
// @ts-ignore
import {saveAs} from "file-saver";

export function Shipment() {
    const [orders, setOrders] = useState<Array<OrderInterface>>([]);

    const [pagination, setPagination] = useState<PaginationInterface|any>({...structPagination, date_before_number: 7})
    const [loading, setLoading] = useState(true);
    const [showFilterCombobox, setShowFilterCombobox] = useState(false);

    UseExtLayout();
    useEffect(() => {
        if (loading) {
            loadOrder().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadOrder = async () => {
        try {
            await OrderApi.getShipment(pagination).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    response = response.data?.data;
                    setOrders(
                        response?.data || []
                    )

                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    return (
        <React.Fragment>
            {/*<QModal
                open={deliveryClick > 0}
                setOpen={() => { setDeliveryClick(0) }}
                headerContent={'Shipment method'}
                bodyContent={(
                    <BodyDeliveryComponent
                        setOpen={setDeliveryClick}
                        order={order}
                    />
                )}
            />*/}
            <div className="col-md-12">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name fw-bold">Statistics</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a
                                        href="#"
                                        className="btn btn-sm bdr-10 bg-transparent btn-border ml-3"
                                        onClick={async () => {
                                            //let response: ResponseInterface|any = await ShipmentApi.exportData();
                                           await QHelpers.file.downloadFile(
                                               'shipment_report.xlsx',
                                                config.API_URL + '/order/shipment-export',
                                               true
                                           )
                                        }}
                                    >Export Excel</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="d-flex bdr-10 btn-bd ">
                                                <div className="btn-group w-50">
                                                    <button
                                                        type="button"
                                                        className={`btn bdr-10 input-sm w-75 ${ styles.btnChooseFilterDate }`}
                                                    >
                                                        { pagination?.date_before_number ? (
                                                            pagination.date_before_number == 7 ? "Last 7 days" : "Last 14 days"
                                                        ) : "" }
                                                    </button>
                                                    <button
                                                        onClick={() => { setShowFilterCombobox(!showFilterCombobox) }}
                                                        type="button"
                                                        className={`btn input-sm dropdown-toggle dropdown-toggle-split bdr-0 bd-right ${showFilterCombobox ? 'show' : ''}`}
                                                        id="dropdownDay"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="true" data-bs-reference="parent"
                                                    >
                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                    </button>
                                                    <ul
                                                        className={`dropdown-menu ${showFilterCombobox ? 'show dropdown-choose-date-shipment' : ''}`}
                                                        aria-labelledby="dropdownDay"
                                                        data-popper-placement="bottom-start"
                                                    >
                                                        <li>
                                                            <a
                                                                className="dropdown-item" href="#"
                                                                onClick={ async () => {
                                                                    await setPagination({
                                                                        ...pagination,
                                                                        page: 1,
                                                                        date_before_number: 7,
                                                                    })
                                                                    await setLoading(true);
                                                                    await setShowFilterCombobox(false)
                                                                }}
                                                            >Last 7</a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="dropdown-item" href="#"
                                                                onClick={ async () => {
                                                                    await setPagination({
                                                                        ...pagination,
                                                                        page: 1,
                                                                        date_before_number: 17,
                                                                    })

                                                                    await setLoading(true);
                                                                    await setShowFilterCombobox(false)
                                                                }}
                                                            >Last 14</a>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <hr className="dropdown-divider" />*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <a className="dropdown-item" href="#">Separated link</a>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                                <div>
                                                    <input
                                                        type="date"
                                                        className="form-control input-sm bd-none input-date bdr-0 bdr-r10 ml-2"
                                                        placeholder=""
                                                        onChange={ async (event: any) => {
                                                            await setPagination({
                                                                ...pagination,
                                                                date_filter: event.target.value,
                                                            })
                                                            await setLoading(true);
                                                            await setShowFilterCombobox(false)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <QSearch
                                    onChange={ async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                                <QSelectPerPage pagination={pagination} setPagination={setPagination} setLoading={setLoading} />
                            </div>
                            <QLoading loading={loading}/>
                            <div className="row mt-1 ">
                                <div className="col-6 text-center">
                                    <p className="fw-bold fs-24 mt-3">Information from 2app shipping</p>
                                </div>
                                <div className="col-6 text-center">
                                    <p className="fw-bold fs-24 mt-3">Shipping company</p>
                                </div>
                            </div>
                            <table className="table align-middle table-nowrap mb-0 bd-top" id="customerTable">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col" style={{width: "14%"}}>Order no.</th>
                                    <th scope="col" style={{width: "12"}}>Customer</th>
                                    <th scope="col" style={{width: "12%"}}>Shipped date</th>
                                    <th className="text-center bd-right" scope="col" style={{width: "12%"}} >Weight</th>
                                    <th scope="col" style={{width: "10%"}} >Track & Trace</th>
                                    <th className="text-center" scope="col" style={{width: "10"}} >Shipping company</th>
                                    <th scope="col" style={{width: "10"}} >Shipping Service</th>
                                    <th scope="col" style={{width: "10%"}} >Parcel Number</th>
                                </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                {orders?.map((order: OrderInterface, index: number) => {
                                    return (
                                        <tr>
                                            <td>{order.orderNumber}</td>
                                            <td>{order.recipient_name}</td>
                                            {/*<td>{order.shipped_date?(<Moment date={order.shipped_date} format="DD.MM.YY"/>):''}</td>*/}
                                            <td>{moment(order.shipped_date).isValid()?moment(order.shipped_date).format('DD.MM.YY'):''}</td>
                                            <td className="text-center bd-right">{order.weight}</td>
                                            <td><span className="color-danger">{order.traceandtrace}</span></td>
                                            <td className="text-center"><img src={ProductIcon}/></td>
                                            <td>{order.shipment_service}</td>
                                            <td><span className="text_under">{order.ParcelNumber}</span></td>
                                        </tr>
                                    );
                                })}

                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                        {/*<div className="noresult">*/}
                        {/*    <div className="text-center">*/}
                        {/*        /!*<lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"*!/*/}
                        {/*        /!*           colors="primary:#121331,secondary:#08a88a"*!/*/}
                        {/*        /!*           style={{width: '75px', height: '75px'}}>*!/*/}
                        {/*        /!*</lord-icon>*!/*/}
                        {/*        <h5 className="mt-2">Sorry! No Result Found</h5>*/}
                        {/*        <p className="text-muted mb-0">We've searched more than 150+ contacts We did not find any*/}
                        {/*            contacts for you search.</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
