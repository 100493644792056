import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import common_en from "./en/common.json";
import order_en from "./en/order.json";
import common_vi from "./vi/common.json";

i18next
    .use(initReactI18next)
    .init({
        debug: true,
        resources: {
            en: {
                translation: {
                    common: common_en,
                    order: order_en
                }
            },
            vn: {
                translation: {
                    common: common_vi,
                }
            },
        },
        lng: 'en',
        fallbackLng: 'vn',
        whitelist: ['vn', 'en'],

        interpolation: {
            escapeValue: false,
        },
    });
