import React, {useEffect} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import {QHelpers} from "../../../../common/helpers";
import {config} from "../../../../config";

interface Props {
    formik: any
}

const Step1EconomicInfo: React.FC<Props> = ({formik}) => {

    useEffect(() => {
        let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)

        if(objectParams?.token){
            formik.setFieldValue('economic_token', objectParams.token)
        }
    }, [])

    return(
        <React.Fragment>
            <QTextBox
                error={formik.errors?.economic_token}
                label={'Token'}
                value={formik.values?.economic_token}
                onChange={(event: any) => {
                    formik.setFieldValue('economic_token', event);
                }}
            />
            <div className="row">
                 <div className="col-md-12 mb-2"><a href={QHelpers.string.generateUrlEconomic(config.INTEGRATION.ECONOMIC.REDIRECT_URL_SETUP)}>Generate new token</a></div>
            </div>
        </React.Fragment>
    )
}

export default Step1EconomicInfo;
