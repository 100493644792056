import React, {useState} from "react";
import {Link} from "react-router-dom";
import {RouteDefine} from "../../routes/RouteDefine";

interface Props {

}

const LeftSideBar01: React.FC<Props> = () => {
    const [stateUrl, setStateUrl] = useState(window.location.pathname)

    return (
        <div className="col-md-auto">
            <div className="siderbar-nav-left mt-3 w-195">
                <h3 className="fs-16 cl-ams fw-bold mb-4">Settings</h3>
                <ul className="pl-0">
                    <li className={`${stateUrl === RouteDefine.business ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.business)
                        }}
                    >
                        <Link to={RouteDefine.business}>Business Info</Link>
                    </li>
                    {/*<li className={`${stateUrl === RouteDefine.integrate ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.integrate)
                        }}
                    >
                        <Link to={RouteDefine.integrate}>Integration</Link>
                    </li>*/}
                    <li className={`${stateUrl === RouteDefine.economic ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.economic)
                        }}
                    >
                        <Link to={RouteDefine.economic}>E-conomic</Link>
                    </li>
                    <li className={`${stateUrl === RouteDefine.shippingInformation ? 'active' : ''}`}
                        onClick={() => {
                            setStateUrl(RouteDefine.shippingInformation)
                        }}
                    >
                        <Link to={RouteDefine.shippingInformation}>Shipping Information</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default LeftSideBar01;
