const padTo2Digits = (num: number|any) => {
    return num.toString().padStart(2, '0');
}


function formatDate(date: Date|any) {
    if(!date) return '';

    if(date && typeof date === "string") return date;

    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}

export const __date = {
    formatDate
}