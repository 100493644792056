export const config = {
    // API_URL: "http://localhost:8000/api",
    // API_URL: "http://localhost/2app-shipping-e-conomic/public/api",
    API_URL: "http://2app-economic-shipping.dragonflyteam.com/back/public/api",
    // API_URL: "http://t430.dragonflyteam.com/back/public/api",
    APP_NAME: "",
    STATUS_CODE: {
        OK: 200,
        BAD_REQUEST: 400,
        SERVER_ERROR: 500,
    },
    LOCAL_STORE: {
        ACCOUNT: 'account',
        ACCESS_TOKEN: 'access_token',
    },
    TOAST_TYPE: {
        INFO: 'info',
        SUCCESS: 'success',
        ERROR: 'error',
        WARNING: 'warning',
    },
    INTEGRATION: {
        ECONOMIC: {
            REDIRECT_URL: 'http://2app-economic-shipping.dragonflyteam.com/economic',
            // REDIRECT_URL: 'http://localhost:3000/economic',
            PUBLIC_TOKEN: "xEWLdWWSPz44evs2ENyBJnuO24k8XAXZ1LHnBd78Ww41",
            REDIRECT_URL_SETUP: "http://2app-economic-shipping.dragonflyteam.com/setup",

        }

    }
}
