import React, {useCallback, useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import OrderApi from "../../../common/api/functions/Order";
import {OrderInterface} from "../../../interfaces/private/OrderInterface";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import QPagination from "../../../common/components/QPaging";
import QLoading from "../../../common/components/QLoading";
import ProductIcon from "../../../assets/images/products/image_15.png";
import {Link} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import BodyDeliveryComponent from "./components/BodyDelivevyComponent";
import {UseMainLayout} from "../../../context/LayoutContext";
import QSearch from "../../../common/components/Genneral/QSearch";
import {OrderConstant} from "../../../common/constant/OrderConstant";
import moment from "moment";
import {config} from "../../../config";
import QHeaderTable from "../../../common/components/Genneral/QHeaderTable";
import {OrderConfig} from "./config";
import QSelectPerPage from "../../../common/components/Genneral/QSelectPerPage";

const Order = () => {
    const [orderChecked, setOrderChecked] = useState <any>({
        isCheckAll: false,
        arrOrder: [],
    });
    const [orders, setOrders] = useState<Array<OrderInterface>>([]);
    const [order, setOrder] = useState<OrderInterface | any>({});
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
        status: OrderConstant.status.open,
        key: 'orderNumber',
        sort: 'desc',
    })
    const [loading, setLoading] = useState(true);
    const [deliveryClick, setDeliveryClick] = useState(0);

    UseMainLayout();
    useEffect(() => {
        if (loading) {
            loadOrder().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadOrder = async () => {
        try {
            await OrderApi.getAll(pagination).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    response = response.data.data;

                    setOrders(
                        response?.data || []
                    )
                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    const renderStatus = useCallback(() => {
        const arrStatus = [];

        for (let s in OrderConstant.status) {
            arrStatus.push(
                <li className="nav-item" key={s}>
                    <a
                        href={"#"}
                        className={`nav-link All py-3 pl-0 ${OrderConstant.status[s] === pagination.status ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        id="All"
                        role="tab"
                        aria-selected="false"
                        onClick={async () => {
                            await setPagination({
                                ...pagination,
                                status: OrderConstant.status[s]
                            })

                            await setLoading(true)
                        }}
                    >
                        {OrderConstant.statusTitle[s]}
                    </a>
                </li>
            )
        }


        return arrStatus;
    }, [pagination.status])

    return (
        <React.Fragment>
            <BodyDeliveryComponent
                order={order}
                open={deliveryClick}
                setOpen={setDeliveryClick}
                setLoading={setLoading}
            />

            <div className="col-md-12">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">Orders</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a className="btn btn-sm  bg-orange-zp ml-3" onClick={() => {
                                    }}>
                                        <span><i className="mdi mdi-truck-fast-outline mr-2"/> Ship</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                        {renderStatus()}
                                    </ul>
                                </div>
                                <QSearch
                                    onChange={async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                                <QSelectPerPage pagination={pagination} setPagination={setPagination} setLoading={setLoading} />
                            </div>
                            <QLoading loading={loading}/>
                            <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                <QHeaderTable
                                    paging={pagination}
                                    setPaging={ async (p: any) => {
                                        await setPagination(p);
                                        await setLoading(true);
                                    }}
                                    headers={OrderConfig.headers}
                                    funcChecked={() => {

                                        let arrOrder = [];
                                        if(!orderChecked.isCheckAll){
                                            arrOrder = orders?.map((o: OrderInterface) => o.orderNumber);
                                        }

                                        setOrderChecked({
                                            ...orderChecked,
                                            isCheckAll: !orderChecked.isCheckAll,
                                            arrOrder: arrOrder,
                                        })
                                    }}
                                />
                                <tbody className="list form-check-all">
                                {orders?.map((order: OrderInterface, index: number) => {
                                    let checkedOrderIndex: any|OrderInterface = orderChecked.arrOrder?.findIndex((o: number) => o === order.orderNumber);
                                    return (
                                        <tr key={index}>
                                            <th scope="row">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input chk_child"
                                                        type="checkbox"
                                                        name="chk_child"
                                                        checked={checkedOrderIndex > -1}
                                                        defaultValue="option1"
                                                        onClick={(event: any) => {
                                                            if(checkedOrderIndex > -1){
                                                                let clone = {...orderChecked};
                                                                clone.arrOrder.splice(checkedOrderIndex, 1);
                                                                setOrderChecked(clone)
                                                            }else{
                                                                let clone = {...orderChecked};
                                                                clone.arrOrder.push(order.orderNumber);
                                                                setOrderChecked(clone)

                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            <td>
                                                <a className={"colo"}>
                                                    <Link to={`${RouteDefine.order}/${order.id}`}>
                                                        {order.orderNumber}
                                                    </Link>
                                                </a>
                                            </td>
                                            <td>  {order.recipient_name}</td>
                                            <td>{(moment(order.date)).isValid() && moment(order.date).format('DD.MM.YY')}</td>
                                            <td>{(moment(order.delivery_deliveryDate)).isValid() && moment(order.delivery_deliveryDate).format('DD.MM.YY')}</td>
                                            {/*<td>{(order.delivery_deliveryDate == "")?'':<Moment date={order.delivery_deliveryDate} format="DD.MM.YY"/>}</td>*/}
                                            <td>
                                                {(order.delivery_address ? (order.delivery_address + ',') : '')
                                                    + (order.delivery_zip ? (" " + order.delivery_zip) : '')
                                                    + (order.delivery_city ? (" " + order.delivery_city) : '')
                                                    + (order.delivery_country ? (" " + order.delivery_country) : '')}
                                            </td>
                                            <td className="text-right">{order.weight} </td>

                                            <td className="text-center">
                                                <div className={((s: number) => {
                                                    s = s ?? 1;
                                                    return "status-" + (s == 1 ? 'open' : (s == 2 ? 'on-hold' : 'shipped'));
                                                })(order.status)}>{order.status_name}</div>
                                            </td>
                                            <td>
                                                {order.status == 3 ? <img src={ProductIcon}/> : null}
                                            </td>
                                            <td>
                                                {order?.shipment_service}
                                            </td>
                                            <td className="text-end">
                                                <a className={"cursor-pointer"} onClick={() => {
                                                    setDeliveryClick(order.id);
                                                    setOrder(order)
                                                }}>
                                                    {order.status == 1 || order.status == null ?
                                                        <i className="mdi mdi-truck-fast-outline fs-16 cl-br-ams"/> : null}
                                                </a>
                                                <Link to={`${RouteDefine.order}/${order.id}`}>
                                                    <i className="las la-pen fs-16 cl-br-ams ml-2"/>
                                                </Link>

                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default React.memo(Order);
