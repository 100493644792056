import React from "react";

interface Props {
    type?: 'success' | 'default' | 'warning' | 'primary' | string,
    width?: string|any,
    btnType?: string|any,
    content?: string,
    onClick?: Function|any,
    className?: string|any,
    size?: string|any
}

const QButton: React.FC<Props> = ({
    type = 'default',
    width = 5,
    btnType = 'button',
    content = 'default',
    onClick = null,
    size = 'btn-sm',
    className ='',
}) => {

    const renderWidth = () => {
        if(width && typeof width === "string"){
            return width
        }

        if(width && typeof width === "number"){
            return `w-${width}`;
        }

        return '';
    }

    const renderType = () => {
        switch (type)
        {
            case 'default':
                return 'bg-brown';
            case 'primary':
                return 'bg-orange-zp'
                //return 'bg-light-blue-zp'
        }

        return '';
    }

    return (
        <React.Fragment>
            <button
                className={`btn ${renderType()} ${size} ${renderWidth()} ${className}`}
                type={btnType}
                onClick={() => {
                    if(onClick) onClick();
                }}
            >
                { content }
            </button>
        </React.Fragment>
    )
}

export default QButton
