import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {ToastInterface} from "../interfaces/ToastInterface";

interface InitialInterface {
    toast: ToastInterface,
}

const initial: InitialInterface = {
    toast: {
        message: '',
        position: 'bottom-right',
        autoClose: 10000,
        closeOnClick: true,
        type: ''
    }
}

export const RootReducerSlice = createSlice({
    name: 'RootReducerSlice',
    initialState: initial,
    reducers: {
        setToast: (state, action) => {
            state.toast.message = action.payload.message;
            state.toast.type = action.payload.type;
        }
    }
})


// export const selectorRootReducer = (state: RootState) => state.

export const  {
    setToast
} = RootReducerSlice.actions;
export default RootReducerSlice.reducer
