import React from "react";
import { withRouter } from 'react-router-dom';

function NonLayoutTemplate({children} : any) {
    return (
        <>
            { children }
        </>
    )
}

export default withRouter(NonLayoutTemplate);