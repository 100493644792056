import {APIClient} from "../base";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";

const OrderApi = {
    getAll: async (pagination: PaginationInterface) => await APIClient.get('/order', pagination),
    getDetail: async (id: number|string|any) => await APIClient.get('/order/' + id, {}),
    postShipment: async (orderId: number, model: object|any) => await APIClient.create(`/order/${orderId}/shipping`, model),
    getShipment: async (pagination: PaginationInterface) => await APIClient.get('/order/shipment', pagination),
    getAllLog: async (orderId: number|any) => await APIClient.get(`/order/${orderId}/log`, {}),
}

export default OrderApi;
