import { APIClient } from "../base";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";

const UserApi = {
    register: async (model: any) => await APIClient.create('/register', model),
    ResetPassword: async (model: { password_confirm: string, password: string, email: string, sign: string, expire: string }) => await APIClient.create('/reset-password', model),
    ForgotPassword: async (model: {  email: string}) => await APIClient.create('/forgot-password', model),
    ChangePassword: async (model: {  current_password: string, new_password: string, new_password_confirm: string}) => await APIClient.create('user/change-password', model),
}

export default UserApi;
