import React from "react";
import Step1EconomicInfo from "../components/Step1EconomicInfo";
import Step2EconomicWebHook from "../components/Step2EconomicWebHook";
import Step3AccountShip from "../components/Step3AccountShip";
import StepSuccess from "../components/StepSuccess";
import StepError from "../components/StepError";

const STEP = {
    step1: 1,
    step2: 2,
    step3: 3,
    step4: 4,
}

const arrStepList = [
    {
        id: 1,
        label: ' Economic',
        component: Step1EconomicInfo
    },
    {
        id: 2,
        label: ' Webhook',
        component: Step2EconomicWebHook
    },
    {
        id: 3,
        label: ' Shipping Information',
        component: Step3AccountShip,
    },
    {
        id: 4,
        label: '',
        component: StepSuccess,
    },
    {
        id: 5,
        label: '',
        component: StepError,
    },
]

export const getValidation = (step: number, yup: any) => {
    switch (step) {
        case 1:
            return yup.object().shape({
                economic_token: yup.string().required(),
            })
        case 2: yup.object().shape({})
    }
}

export const SetupConfig = (step: number, formik: any) => {

    const getStep: any = arrStepList.find((s: any) => s.id === step);

    const Component = getStep.component;
    return (
        <React.Fragment>
            { getStep?.label ? (
                <h2 className="mb-4 mt-3 fs-32 cl-orange-zp fw-bold">
                    Step { getStep.id }:
                    <span className="fw-400">{ getStep.label }</span>
                </h2>
            ) : null }
            { <Component formik={formik} /> }
        </React.Fragment>
    )
}
