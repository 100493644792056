// @ts-ignore
import {saveAs} from "file-saver";
import {__object} from "./__object";
import {config} from "../../config";

const convertFileObjectToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}

const convertBase64ToObjectFile = (base64: string, fileName: string) => {
    try {
        let arr = base64.split(',');
        if(arr.length){
            // @ts-ignore
            let mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], fileName, {type:mime});
        }
    }catch (err){ }

    return null
}

const convertUrlFileToBase64 = async (url: string, isAuth: boolean = false) => {
    let headers : any = {};
    if(isAuth){
        let localData = __object.getItemLocal(config.LOCAL_STORE.ACCOUNT);
        try {
            localData = JSON.parse(localData);
            if(localData?.access_token){
                headers.Authorization = `Bearer ${localData.access_token}`;
            }
        }catch (err){}
    }

    let response = await fetch(url, {
        headers: headers
    });
    let data = await response.blob();

    return blobToBase64(data);
}

const blobToBase64 = (blob: any) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

const convertUrlFileToObjectFile = async (url: string, fileName: string = '') => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        if(!fileName){
            let arrUrl = url.split('/');
            fileName = arrUrl[arrUrl.length - 1];
        }

        return  new File([blob], fileName, {type: blob.type});
    }catch (err){ }
    return null;
}

const downloadFile = async (fileName: string, path: string, isAuth: boolean = false) => {
    try {
        if(!fileName){
            let arr = path?.split('/');
            fileName = arr[arr.length - 1];
        }

        let base64 : any= await __file.convertUrlFileToBase64( path, true);
        let objectFile: File|any = await __file.convertBase64ToObjectFile(base64, fileName)
        let blob = new Blob([objectFile], {type: base64.substring(base64.indexOf(":")+1, base64.indexOf(";"))});
        saveAs(blob, fileName)
    }catch (err){ }
}

export const __file = {
    convertFileObjectToBase64,
    convertBase64ToObjectFile,
    convertUrlFileToBase64,
    convertUrlFileToObjectFile,
    downloadFile,
}