import React, {useState} from "react";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";
import {TableHeaderInterface} from "../../../interfaces/TableHeaderInterface";
import QIconSortAsc from "../QIcon/QIconSortAsc";
import QIconSortDesc from "../QIcon/QIconSortDesc";
import "./index.css";

interface Props {
    paging: PaginationInterface,
    setPaging: Function,
    headers: Array<TableHeaderInterface>,
    funcChecked?: Function|any,
}

const QHeaderTable: React.FC<Props> = ({paging, setPaging, headers, funcChecked}) => {

    const [checked, setChecked] = useState(false);

    const renderSortIcon = (item: TableHeaderInterface) => {
        if(paging.key === item.sort){
            let content =  paging.sort.toLowerCase() === 'asc' ? <QIconSortAsc /> : <QIconSortDesc />

            return (
                <div className={'cursor-pointer qIconActive'} onClick={() => {
                    setPaging({
                        ...paging,
                        sort: paging.sort?.toLowerCase() === "asc" ? "desc" : "asc",
                    })
                }}>
                    <span className={'mr-2'}>{ item.label }</span>
                    { content }
                </div>
            )
        }

        if(item.sort){
            return (
                <div className={`cursor-pointer qIcon`} onClick={() => {
                    setPaging({
                        ...paging,
                        sort: 'asc',
                        key: item.sort,
                    })
                }}>
                    { item.label }
                    <QIconSortAsc className={''} />
                </div>
            )
        }

        return item.label;
    }

    return (
        <React.Fragment>
            <thead className="table-light">
                <tr>
                    { headers?.map((item: TableHeaderInterface, index: number) => {
                        if(item.checkbox){
                            return (
                                <th key={index} scope="col" style={{width: '50px'}}>
                                    <div className="form-check">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkAll"
                                                checked={checked}
                                                defaultValue="option"
                                                onClick={ async () => {
                                                    await setChecked(!checked);
                                                    if(funcChecked) await funcChecked();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </th>
                            )
                        }

                        return (
                            <th key={index} scope="col" className={item.className}>
                                { renderSortIcon(item) }
                            </th>
                        )
                    }) }
                </tr>
            </thead>
        </React.Fragment>
    )
}

export default QHeaderTable;
