import React from "react";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";

interface Props {
    pagination: PaginationInterface,
    setPagination: Function,
    setLoading: Function,
}

const QSelectPerPage: React.FC<Props> = ({pagination, setPagination, setLoading}) => {
    return (
        <React.Fragment>
            <div className="text-center text-muted fs-12 d-flex ml-2 " style={{ marginTop: '-5px' }}>
                <div className="entries">
                    <select
                        className="form-select form-select-zp text-muted fs-12 "
                        defaultValue={pagination.per_page}
                        onChange={ async (event: any) => {
                            await setPagination({
                                ...pagination,
                                per_page: event.target.value,
                            })

                            if(setLoading) await setLoading(true);
                        }}
                    >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            </div>
        </React.Fragment>
    )
}

export default QSelectPerPage;
