import React, {useEffect, useState} from "react";
import QModal from "../../../../common/components/QModal";
import BodyProductDetailComponent from "../../Product/components/BodyProductDetailComponent";
import PopupLog from "./PopupLog";
import QButton from "../../../../common/components/QButton";
import OrderApi from "../../../../common/api/functions/Order";
import {LogOrderInterface} from "../../../../interfaces/private/OrderInterface";

interface Props {
    orderId: number|any
}

const Log: React.FC<Props> = ({orderId}) => {
    const [openLog, setOpenLog] = useState(false);
    const [logs, setLogs] = useState <Array<LogOrderInterface>>([]);
    useEffect(() => {
        if(orderId){
            OrderApi.getAllLog(orderId).then((response: any) => {
                setLogs(
                    Array.isArray(response?.data?.data) ? response.data.data : []
                )
            })
        }
    }, [orderId])

    return (
        <React.Fragment>
            <div className="order-channel bg-border-pd mb-3">
                <p className="fw-bold fs-16 mb-1">Logs</p>
                <QButton
                    size={'btn-sm'}
                    className={'bg-transparent btn-border w-100 bdr-10'}
                    content={'Show logs'}
                    onClick={() => { setOpenLog(true) }}
                />
            </div>

            <QModal
                open={openLog}
                setOpen={() => { setOpenLog(false) }}
                headerContent={'Logs'}
                bodyContent={(
                    <PopupLog logs={logs}  />
                )}
            />
        </React.Fragment>
    )
}

export default Log;
