import React from "react";

interface Props {
    className?: string
}

const QIconSortAsc: React.FC<Props> = ({ className }) => {
    return (
        <i className={`fa fa-sort-alpha-asc ${className}`} aria-hidden="true" />
    )
}

export default QIconSortAsc