import React, {useEffect, useState} from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import {useHistory, useLocation} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import {useDispatch} from "react-redux";
import {setToast} from "../../../app/rootReducer";
import {QHelpers} from "../../../common/helpers";
import QModal from "../../../common/components/QModal";

export function Login() {
    const history = useHistory();
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);

    useEffect(() => {
        try {
            let session: any = localStorage.getItem(
                config.LOCAL_STORE.ACCOUNT,
            )

            session = JSON.parse(session);
            if(session?.access_token) {
                history.goBack();
            }

        }catch (err){}
    }, [])

    useEffect(()=>{
        if(objectParams.verify == 1){
            setOpen(true);
        }
    },[objectParams.verify])

    const formik = useFormik({
        initialValues: {
            email: 'admin@yopmail.com',
            password: 'password',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values) => {
            await setLoading(true);
            await AuthApi.signIn(values).then(async (response:ResponseInterface|any)=>{

                if (response?.status === config.STATUS_CODE.OK){
                    dispatch(setToast({
                        message: "Login success.",
                        type: config.TOAST_TYPE.SUCCESS,
                    }))
                    await localStorage.setItem(
                        config.LOCAL_STORE.ACCOUNT,
                        JSON.stringify(response.data.data),
                    )

                    if(response?.data?.data?.user?.is_logged == 0){
                        await history.push(RouteDefine.setup);
                    }else{
                        await history.push(RouteDefine.order)
                    }
                }

            },(response:ResponseInterface|any)=>{

                if(typeof response.data?.message == "string"){
                    dispatch(setToast({
                        message: response.data?.message,
                        type: config.TOAST_TYPE.ERROR,
                    }))
                }
            });
            await setLoading(false);
        }
    })

    return (
        <div>
            <div className="mt-4 position-relative">
                <QLoading loading={loading} />
                <QModal
                    open={open}
                    setOpen={() => {
                        setOpen(false)
                    }}
                    loading={loading}
                    headerContent={'Verify Account'}
                    bodyContent={(
                        <React.Fragment>
                            <div>Your account is verified success. You can login now.</div>
                        </React.Fragment>
                    )}
                />
                <form action="order.html" method="">
                    <h2 className="mb-4 mt-3 fs-32 cl-orange-zp fw-400"> Sign in</h2>

                    <QTextBox
                        label={'Email'}
                        placeholder={'Enter username'}
                        value={formik.values.email}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('email', value)
                        }}
                        error={formik.touched?.email && formik.errors?.email}
                    />

                    <QTextBox
                        type={'password'}
                        label={'Password'}
                        placeholder={'Enter password'}
                        value={formik.values.password}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('password', value)
                        }}
                        error={formik.touched?.password && formik.errors?.password}
                    />

                    <div className="mt-4">
                        <QButton
                            content={'Sign In'}
                            type={'success'}
                            width={'w-100'}
                            size={''}
                            className={'btn bg-orange-zp w-100 bdr-10 fs-16'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <br/>
                        <br/>
                        <p><a className="cl-orange-zp fw-bold" href={''} onClick={()=>{history.push(RouteDefine.forgotPassword)}}>Forgot your password?</a></p>
                        <p><span>Don't have an account? </span>
                            <a className="cl-orange-zp fw-bold" href={"#"} onClick={()=>{history.push(RouteDefine.register)}}>Sign up</a>
                        </p>
                    </div>

                </form>
            </div>
        </div>
    )
}
