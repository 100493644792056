import React from "react";
import {LogOrderInterface} from "../../../../interfaces/private/OrderInterface";

interface Props {
    logs: Array<LogOrderInterface>
}

const PopupLog: React.FC<Props> = ({logs}) => {

    return (
        <React.Fragment>
            <div className="timeline-2 timeline-ct-2 mt-3">
                <div className="timeline-continue">
                    { logs?.map((log: LogOrderInterface, index: number) => {
                        return (
                            <div className="d-flex timeline-right mb-5" key={index}>
                                <div className="w-140">
                                    { log.time_ago }
                                </div>
                                <div className="w-auto position-relative">
                                    <p className="timeline-date">
                                        { log.message }
                                    </p>
                                </div>
                            </div>
                        )
                    }) }
                </div>
            </div>
        </React.Fragment>
    )
}

export default PopupLog;
