import React from "react";

interface Props {
    type: 'danger'|'success'|'warning'|'info'|string,
    message?: string,
}

const QAlert: React.FC<Props> = ({type, message}) => {

    return (
        <React.Fragment>
            <div className={`alert alert-${type} mb-xl-0`} role="alert">
                { message }
            </div>
        </React.Fragment>
    )
}

export default QAlert;