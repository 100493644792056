import React from "react";
import IconShipment from "../../../../assets/images/ams/Truck icon.png";
// @ts-ignore
import { saveAs } from 'file-saver';
import {QHelpers} from "../../../../common/helpers";

interface Props {
    trackAndTrace: string|any,
    label: string|any,
}

const ShipmentNoComponent: React.FC<Props> = ({trackAndTrace,label}) => {
    return (
        <React.Fragment>
            <div className="bg-border-pd mb-3">
                <p className="fw-bold fs-20 mb-1">Shipping information</p>
                <div className="text-center">
                    <a><img src={IconShipment}/></a>
                    <p className="fw-bold fs-16 mb-1">Track &amp; Trace</p>
                    <a><span className="m"><i className="mdi mdi-eye-outline fs-16 me-2"></i></span><span
                        className="cl-orange-zp"><a className={"cl-orange-zp"} target={"_blank"} href={"https://gls-group.eu/EU/en/parcel-tracking?match=" + trackAndTrace}>{ trackAndTrace }</a></span></a>
                    <div className=" d-flex justify-content-center">
                        <div className="status-shipped mt-2 w-25"><a href={"#"}
                            onClick={ async () => {
                               if(label){
                                   let objectFile = await QHelpers.file.convertBase64ToObjectFile("data:application/pdf;base64," + label, trackAndTrace + ".pdf");
                                   saveAs(objectFile, trackAndTrace+ ".pdf")
                               }else{

                               }

                            }}
                        >Print Label PDF</a> </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default ShipmentNoComponent
