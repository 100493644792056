import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";


import {RouteDefine} from "../../routes/RouteDefine";
import {config} from "../../config";
import {setAuthorization} from "../../common/api/base";

const AuthProtected = (props: any) => {
    let store: string|any = localStorage.getItem(config.LOCAL_STORE.ACCOUNT);
    store = JSON?.parse(store);
    setAuthorization(store?.access_token);

    if (!store?.access_token) {
        return (
            <Redirect to={{ pathname: RouteDefine.login, state: { from: props.location } }} />
        );
    }

    return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest } : any) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (<> <Component {...props} /> </>);
            }}
        />
    );
};

export { AuthProtected, AccessRoute };
