import {RouteInterface} from "../interfaces/RouteInterface";
import {RouteDefine} from "./RouteDefine";
import {Dashboard} from "../features/private/Dashboard";
import Order from "../features/private/Order";
import Product from "../features/private/Product";
import Customer from "../features/private/Customer";
import {Shipment} from "../features/private/Shipment";
import {Integrate} from "../features/private/Integrate";
import {Business} from "../features/private/Business";
import {Economic} from "../features/private/Economic";
import OrderDetail from "../features/private/OrderDetail";
import ShippingInformation from "../features/private/ShippingInformation";

export const privateRoute: Array<RouteInterface> = [
    {path: RouteDefine.shipment, Component: Shipment},
    {path: RouteDefine.dashboard, Component: Dashboard},
    {path: RouteDefine.order, Component: Order},
    {path: RouteDefine.product, Component: Product},
    {path: RouteDefine.customer, Component: Customer},

    {path: RouteDefine.integrate, Component: Integrate},
    {path: RouteDefine.business, Component: Business},
    {path: RouteDefine.economic, Component: Economic},

    {path: RouteDefine.orderDetail, Component: OrderDetail},
    {path: RouteDefine.shippingInformation, Component: ShippingInformation},

]
