import React, {useState} from "react";
import {Props} from "react-select";
import {RouteDefine} from "../../routes/RouteDefine";
import {Link} from "react-router-dom";


const Navbar: React.FC<Props> = () => {
    let urlPath = window.location.pathname;
    const [stateUrl, setStateUrl] = useState(window.location.pathname);

    return <React.Fragment>
        <div className="app-menu navbar-menu">
            <div id="scrollbar">
                <div className="container-fluid">
                    <ul className="navbar-nav" id="navbar-nav">
                        <li className="nav-item">
                            <Link className={"nav-link menu-link menu-link-ams " + ((RouteDefine.order === urlPath || RouteDefine.customer === urlPath || RouteDefine.product === urlPath)
                                ? ' active' : '')} to={RouteDefine.order}>
                                <span data-key="t-home" className="fs-14">Shipping management</span>
                            </Link>
                            <div className="menu-dropdown p-0">
                                <ul className="nav nav-sm flex-column">
                                        <li className={`nav-item ${stateUrl === RouteDefine.order ? ' active' : ''}`}
                                            onClick={() => {
                                                setStateUrl(RouteDefine.order)
                                            }}
                                        >
                                            <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.order}>Orders</Link>
                                        </li>
                                        <li className={`nav-item  ${stateUrl === RouteDefine.product ? ' active' : ''}`}
                                            onClick={() => {
                                                setStateUrl(RouteDefine.product)
                                            }}
                                        >
                                            <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.product}>Products</Link>
                                        </li>
                                        <li className={`nav-item ${stateUrl === RouteDefine.customer ? ' active' : ''}`}
                                            onClick={() => {
                                                setStateUrl(RouteDefine.customer)
                                            }}
                                        >
                                            <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.customer}>Customers</Link>
                                        </li>
                                </ul>
                            </div>
                        </li> {/* end Dashboard Menu */}
                        <li className="nav-item">
                            <Link to={RouteDefine.business} className="nav-link menu-link menu-link-ams fs-14">Settings</Link>
                        </li>
                        <li className={"nav-item" }>
                            <Link
                                to={RouteDefine.shipment}
                                className={"nav-link menu-link menu-link-ams fs-14" + (RouteDefine.shipment === urlPath ? ' active' : '')}
                            >
                                Shipped information
                            </Link>
                        </li>
                        {/* end Dashboard Menu */}
                    </ul>
                </div>
                {/* Sidebar */}
            </div>
            <div className="sidebar-background" />
        </div>
    </React.Fragment>;
};
export default Navbar;
