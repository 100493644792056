import {__object} from "./__object";
import {__date} from "./__date";
import {__file} from "./__file";
import {__string} from "./__string";

export const QHelpers = {
    object: __object,
    date: __date,
    file: __file,
    string: __string,
}