import React, {useContext, useEffect, useState} from "react";

export const LayoutContext = React.createContext(null);

export const LayoutDefine = {
    main:1,
    second:2,
    extend:3,
    second01:4,
}

export const UseMainLayout = () => {
    UseLayout(LayoutDefine.main)
}

export const UseSecondLayout = () => {
    UseLayout(LayoutDefine.second)
}

export const UseSecond01Layout = () => {
    UseLayout(LayoutDefine.second01)
}

export const UseExtLayout = () => {
    UseLayout(LayoutDefine.extend)
}


const UseLayout = (l) => {
    const {updateLayout} = useContext(LayoutContext)
    useEffect(()=>{
        updateLayout(l)
    })
}

export const LayoutContextProvider = ({children}) => {
    const [layout, setLayout] = useState(LayoutDefine.main)
    const updateLayout = (l) => {
        setLayout(l)
    }
    return (<LayoutContext.Provider value={{layout, updateLayout}}>{children}</LayoutContext.Provider>);
}
// export
