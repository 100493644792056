import React, {useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import {UseSecondLayout} from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import BodyShippingInformationComponent from "./components/BodyShippingInformationComponent";
import {SetupInterface} from "../../../interfaces/private/SetupInterface";
import {config} from "../../../config";

export function ShippingInformation() {
    const [setup, setSetup] = useState<SetupInterface>({});
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            load().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                console.log('response', response)
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || []
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={'Business Info'}
                bodyContent={(
                    <BodyShippingInformationComponent model={setup} setOpen={setCreateNewClick} setSetup={setSetup}
                                           setLoadingFunc={setLoading}/>
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading}/>
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">Shipping Information</div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">GLS</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setCreateNewClick(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-1">Username: {setup.gls_username}</p>
                                    <p className="mb-1">Password: {setup.gls_password}</p>
                                </div>
                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(ShippingInformation);
